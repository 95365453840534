import React from 'react'
import { PageProps } from 'gatsby'
import { siteMetadata } from '../../gatsby-config'

import Layout from '../components/Layout'

import PageSection from '../components/PageSection'
import SectionHeaderRow from '../components/SectionHeaderRow'
import Meta from '../components/Meta'

const Impressum: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Impressum" />
      <PageSection>
        <SectionHeaderRow headline="Impressum" />
        <div className="row">
          <div className="col-12">
            <p>
              <span>Angaben gem&auml;&szlig; &sect; 5 TMG</span>
            </p>
            <p><strong>Kontakt</strong></p>
            <p>Veronika Mazouni (Bogusch)<br/>2187 Rue Jules Regnier<br/>78370 Plaisir<br/>Frankreich</p>

            <p>Telefon: +33 949 293 807
              <br/>E-Mail: contact@veronikabogusch.com
            </p>
            <p><strong>Streitschlichtung</strong></p>
            <p>
              <span>
                Die Europ&auml;ische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit:
                http://ec.europa.eu/consumers/odr.
              </span>
            </p>
            <p>
              <span>Unsere E-Mail-Adresse finden Sie oben im Impressum.</span>
            </p>

            <p>
              <span>
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
              </span>
            </p>

            <p><strong>Haftung f&uuml;r Inhalte</strong></p>
            <p>
              <span>
                Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
                f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen
                Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir
                als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte
                oder gespeicherte fremde Informationen zu &uuml;berwachen oder
                nach Umst&auml;nden zu forschen, die auf eine rechtswidrige
                T&auml;tigkeit hinweisen.
              </span>
            </p>

            <p>
              <span>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst
                ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                m&ouml;glich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
              </span>
            </p>

            <p><strong>Haftung f&uuml;r Links</strong></p>
            <p>
              <span>
                Unser Angebot enth&auml;lt Links zu externen Websites Dritter,
                auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen
                wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr
                &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist
                stets der jeweilige Anbieter oder Betreiber der Seiten
                verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e
                &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt
                der Verlinkung nicht erkennbar.
              </span>
            </p>

            <p>
              <span>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                derartige Links umgehend entfernen.
              </span>
            </p>

            <p><strong>Urheberrecht</strong></p>
            <p>
              <span>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
                bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors
                bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.
              </span>
            </p>

            <p>
              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
              wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
              werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
              trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
              bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Inhalte umgehend
              entfernen.
            </p>
          </div>
        </div>
      </PageSection>
    </Layout>
  )
}

export default Impressum
